import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';
import { shouldUseGeolocation } from '../../util/geolocation/config';
import useIpGeolocation from '../../util/geolocation/useIpGeolocation';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingPage.module.css';

const { LatLng } = sdkTypes;

// Higher-order component to add geolocation support to SectionMapMaybe
const withGeolocation = WrappedComponent => {
  return props => {
    const { publicData, listingId, geolocation } = props;
    const authorId = publicData?.authorId;
    const authorName = publicData?.authorName;
    
    // Check if this listing should use user's location
    const shouldUseGeo = shouldUseGeolocation(authorName, authorId);
    
    // Only use geolocation if configured for this profile
    const { userLocation, usingUserLocation, isLoading } = useIpGeolocation(shouldUseGeo);
    
    // Use user's location if available, otherwise fall back to the Sharetribe stored location
    let finalGeolocation = geolocation;
    
    if (shouldUseGeo && usingUserLocation && userLocation?.coords) {
      const lat = parseFloat(userLocation.coords.latitude) || 0;
      const lng = parseFloat(userLocation.coords.longitude) || 0;
      // Create a proper LatLng instance to avoid type errors
      finalGeolocation = new LatLng(lat, lng);
    }
    
    // Use user's location address if available, otherwise fall back to the Sharetribe stored address
    const finalAddress = 
      (shouldUseGeo && usingUserLocation && userLocation?.address)
        ? userLocation.address
        : (publicData?.location?.address || '');
    
    // Create modified props
    const modifiedProps = {
      ...props,
      geolocation: finalGeolocation,
      publicData: {
        ...publicData,
        location: {
          ...publicData?.location,
          address: finalAddress
        }
      },
      isLoadingLocation: shouldUseGeo && isLoading
    };
    
    return <WrappedComponent {...modifiedProps} />;
  };
};

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { className, rootClassName, geolocation, publicData, listingId, isLoadingLocation } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    
    // Show loading indicator if location is being fetched
    if (isLoadingLocation) {
      return (
        <div className={classes}>
          <div style={{ marginBottom: '34px' }}></div>
          <h2 className={css.locationTitle}>
            <FormattedMessage id="ListingPage.locationTitle" />
          </h2>
          <div className={css.map} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
            <p>Loading location...</p>
          </div>
        </div>
      );
    }
    
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    return (
      <div className={classes}>
        <div style={{ marginBottom: '34px' }}></div>
        <h2 className={css.locationTitle}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
        {publicData?.location?.address ? (
          <div className={css.locationText}>
            <b>
              <FormattedMessage id="ListingPage.SectionMapMaybe.addressLabel" />
            </b>
            <p>{publicData.location.address}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
  isLoadingLocation: false,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

// Export the component wrapped with the geolocation HOC
export default withGeolocation(SectionMapMaybe);
